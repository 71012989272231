import { mapActions } from 'vuex'

/**
 * Constant representing the escape keyboard key code.
 *
 * @type {Number}
 */
const ESCAPE_KEY_CODE = 27

/**
 * Panel component mixin.
 *
 * @author Alejandro Sanchez <asanchez@claruscare.com>
 */
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the global event key listener.
		 *
		 * @return {void}
		 */
		addEscapeKeyListener() {
			document.addEventListener('keyup', this.onGlobalKeyUp)
		},

		/**
		 * Fire the close event to all listeners.
		 *
		 * @return {void}
		 */
		close() {
			if (!this.show) {
				return
			}

			this.$emit('close')
		},

		/**
		 * Get the close element reference.
		 *
		 * @return {VueComponent|HtmlElement}
		 */
		getCloseReference() {
			return this.$children[0].$refs.close
		},

		/**
		 * Determine if the escape key was pressed.
		 *
		 * @param {KeyboardEvent} event
		 * @param {Number} event.keyCode
		 * @return {Boolean}
		 */
		isEscapeKey({ keyCode }) {
			return keyCode === ESCAPE_KEY_CODE
		},

		/**
		 * On global key up event listener.
		 *
		 * @param {KeyboardEvent} event
		 * @return {void}
		 */
		onGlobalKeyUp(event) {
			if (this.isEscapeKey(event)) {
				this.close()
			}
		},

		/**
		 * Handle the tab key being pressed on the last focusable element.
		 *
		 * @return {void}
		 */
		onLastElementTab(event) {
			if (!event.shiftKey) {
				event.preventDefault()

				this.getCloseReference().focus()
			}
		},

		/**
		 * Handle the panel being displayed/shown.
		 *
		 * @return {void}
		 */
		onShow() {
			this.lockPageScroll()

			setTimeout(() => {
				this.addEscapeKeyListener()

				this.getCloseReference()?.focus()
			}, 200)
		},

		/**
		 * Remove the global keyup event listener.
		 *
		 * @return {void}
		 */
		removeEscapeKeyListener() {
			document.removeEventListener('keyup', this.onGlobalKeyUp)
		},

		...mapActions('ui', ['lockPageScroll', 'unlockPageScroll']),
	},

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if the panel should be shown.
		 *
		 * @type {Boolean}
		 */
		show: {
			type: Boolean,
			default: false,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the show prop for changes.
		 *
		 * @type {Object}
		 */
		show: {
			/**
			 * Watch the show prop for panel being shown/hidden.
			 *
			 * @param {Boolean} show
			 * @return {void}
			 */
			handler(show) {
				if (show) {
					return this.onShow()
				}

				this.unlockPageScroll()
				this.removeEscapeKeyListener()
			},
			immediate: true,
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.unlockPageScroll()
		this.removeEscapeKeyListener()
	},
}
