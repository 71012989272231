<template>
	<transition name="panel-transition">
		<div v-if="show" class="panel-mask" @click.prevent="close">
			<div
				class="panel-container"
				:class="{ 'panel-large': large }"
				@click.stop
			>
				<div class="panel">
					<div class="panel-header">
						<h3 class="panel-title">
							<slot name="title" />
						</h3>
						<button
							ref="close"
							type="button"
							class="focus:bg-gray-600 focus:outline-none text-white rounded-full h-6 w-6 flex items-center justify-center ml-auto"
							@click="close"
							@keydown.tab.shift.prevent="onFirstElementShiftTab"
						>
							<span class="sr-only">Close the panel</span>
							<font-awesome-icon :icon="['fal', 'times']" />
						</button>
					</div>
					<div class="panel-body">
						<slot />
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the close event to any parent listeners.
		 *
		 * @return {void}
		 */
		close() {
			if (this.show) {
				this.$emit('close')
			}
		},

		/**
		 * Handle the shift+tab key being pressed on the first element.
		 *
		 * @param {KeyboardEvent} event
		 * @return {void}
		 */
		onFirstElementShiftTab() {
			const last = this.$parent.$refs.last

			if (last && last.$el) {
				return last.$el.focus()
			}

			if (last && last.focus) {
				last.focus()
			}
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Panel',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if the panel should be large.
		 *
		 * @type {Boolean}
		 */
		large: {
			type: Boolean,
			default: false,
		},

		/**
		 * The panel's visibility flag.
		 *
		 * @type {Boolean}
		 */
		show: {
			type: Boolean,
			required: true,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for changes.
		 *
		 * @return void
		 */
		$route(current, previous) {
			if (current !== previous) {
				this.close()
			}
		},
	},
}
</script>
