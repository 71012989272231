<template>
	<panel :show="show" @close="close">
		<template #title>
			Direct Message
		</template>
		<template>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<img
					src="@/assets/img/begin_chat.svg"
					class="panel-illustration"
				/>
				<span class="mb-4 font-roboto text-center">
					Create a new conversation
				</span>

				<div class="form-group">
					<label for="members" class="form-label">
						Select Contacts
					</label>
					<multiselect
						id="members"
						ref="multiselect"
						v-model="selectedMembers"
						required
						track-by="id"
						label="label"
						placeholder="Select contacts"
						deselect-label=""
						:options="allContacts"
						:multiple="true"
						:allow-empty="false"
						:select-label="''"
						:selected-label="''"
					>
						<template slot="noResult">
							No partners found
						</template>
						<template slot="singleLabel" slot-scope="{ option }">
							<div class="multiselect-item-content">
								{{ option.label }}
							</div>
						</template>
						<template slot="option" slot-scope="{ option }">
							<div class="multiselect-item-content">
								<span>
									{{ option.label }}
								</span>
							</div>
						</template>
					</multiselect>
				</div>

				<div class="flex justify-between">
					<button
						type="button"
						class="btn btn-danger w-50 mr-2"
						@click.prevent="close"
					>
						<div class="btn-ripple" />

						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'times-octagon']"
						/>
						<span class="btn-label">Cancel</span>
					</button>

					<button
						ref="last"
						type="submit"
						class="btn btn-outline-success w-50 ml-2"
						:disabled="busy"
						@keydown.tab="onLastElementTab"
					>
						<div class="btn-ripple" />
						<font-awesome-icon
							v-if="busy"
							class="btn-icon fa-spin mr-2"
							:icon="['far', 'circle-notch']"
						/>

						<font-awesome-icon
							v-else
							class="btn-icon"
							:icon="['far', 'check']"
							aria-hidden="true"
						/>

						<span class="btn-label">
							Save
						</span>
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import PanelMixin from '@/mixins/PanelMixin'
import StreamChat from '@/app/Chat/StreamChat'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Multiselect,
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the user's available partners sorted alphabetically.
		 *
		 * @return {Array}
		 */
		allContacts() {
			if (!this.members) {
				return []
			}

			return this.members.map(member => {
				const fullName = `${member.last_name}, ${member.first_name}`

				return {
					id: member.id,
					label: fullName,
					first_name: member.first_name,
					last_name: member.last_name,
				}
			})
		},

		...mapGetters({
			activeProfile: 'messagingProfiles/activeProfile',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch the users for the autocomplete.
		 *
		 * @return  {void}
		 */
		async fetchUsers() {
			const user = await this.streamChat.getUser()

			const filters = {
				teams: {
					$contains: this.activeProfile.partner.stream_tenant_id,
				},
				id: {
					$nin: [user.id],
				},
			}

			const response = await this.streamChat.queryUsers(
				filters,
				{ last_active: -1 },
				{}
			)

			this.members = response.users
		},

		/**
		 * Generate a new name for the channel.
		 *
		 * @return {String}
		 */
		generateChannelName() {
			if (!this.selectedMembers) {
				return ''
			}

			if (this.selectedMembers.length === 1) {
				return this.selectedMembers[0].first_name
			}

			return this.selectedMembers
				.map(members => members.first_name)
				.join('-')
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			this.busy = true

			if (this.selectedMembers.length === 0) {
				this.close()
			}

			try {
				const members = this.selectedMembers.map(member => member.id)
				members.push(this.streamChat.getUser().id)

				const team = this.activeProfile.partner.stream_tenant_id
				await this.streamChat.createChannel(
					this.generateChannelName(),
					members,
					team
				)

				this.$alert.success('chat.channel.create.success')
			} catch (e) {
				this.$alert.error('chat.channel.create.error')
			}

			this.busy = false

			this.close()
		},
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CreateChannelPanel',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the show object.
		 *
		 * @return {void}
		 */
		async show() {
			this.members = []
			this.selectedMembers = []

			if (this.show) {
				await this.fetchUsers()
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			members: [],
			selectedMembers: [],
			streamChat: StreamChat,
		}
	},
}
</script>
