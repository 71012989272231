<template functional>
	<div
		class="avatar relative"
		:class="[
			`bg-${props.color}-200`,
			`text-${props.color}-600`,
			`border-${props.color}-800`,
		]"
	>
		<div
			v-if="props.count > 0"
			class="avatar-count absolute  h-6 w-6 text-white border-2 border-gray-300 rounded-full bg-red-500 z-2 text-xs flex justify-center items-center text-center"
		>
			{{ props.count }}
		</div>
		{{ props.name | getInitials }}
	</div>
</template>

<script>
export default {
	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Avatar',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The backgorund and text color to display.
		 *
		 * @type {String}
		 */
		color: {
			type: String,
			default: 'purple',
		},

		/**
		 * The number to display.
		 *
		 * @type {Number}
		 */
		count: {
			type: Number,
			default: 0,
		},

		/**
		 * The name to be shown in the thumbnail.
		 *
		 * @type {String}
		 */
		name: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},
	},
}
</script>
