<template>
	<div class="my-4">
		<button
			href="#"
			class="font-nunito flex justify-center align-items-center p-4 bg-transparent border-0 shadow-none mx-auto font-roboto uppercase"
			v-on="$listeners"
		>
			<slot>Load More</slot>
		</button>
	</div>
</template>

<script>
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get the IntersectionObserver options.
		 *
		 * @return {Object}
		 */
		getOptions() {
			return {
				threshold: this.threshold,
			}
		},

		/**
		 * Determine if the intersect event should be emitted.
		 *
		 * @param {Object} entry
		 * @return {Boolean}
		 */
		shouldEmitIntersectEvent(entry) {
			if (!this.enabled) {
				return false
			}

			return entry && entry.isIntersecting
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Loader',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if the content loader is loading.
		 *
		 * @type {Array}
		 */
		enabled: {
			type: Boolean,
			required: true,
		},

		/**
		 * The percentage of the element that needs to be visible to intersect.
		 *
		 * @type {Number}
		 */
		threshold: {
			type: Number,
			default: 1.0,
		},
	},

	/**
	 * The component's mounted lifecycle hook.
	 *
	 * @return {void}
	 */
	mounted() {
		if (!window.IntersectionObserver) {
			return
		}

		this.observer = new IntersectionObserver(([entry]) => {
			if (this.shouldEmitIntersectEvent(entry)) {
				this.$emit('intersect', entry)
			}
		}, this.getOptions())

		this.observer.observe(this.$el)
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		if (this.observer) {
			this.observer.disconnect()
		}
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			observer: null,
		}
	},
}
</script>
