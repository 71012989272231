<template>
	<div class="flex" :class="{ 'calls-show': hasSubRoute }">
		<div ref="inbox" class="inbox">
			<div class="inbox-header pb-0 sticky top-0 z-50">
				<div class="inbox-title">
					<span class="inbox-title-label">
						{{ partner.name || 'Provider' }}
					</span>
				</div>
			</div>
			<div class="inbox-group border-t-0">
				<div
					class="inbox-group-title flex justify-between items-center"
				>
					<span>Direct Message</span>
					<button
						class="btn btn-sm"
						data-tooltip="New Message"
						data-position="left"
						@click.prevent="onCreateChannel"
					>
						<div class="btn-ripple"></div>
						<span class="sr-only">New Message</span>
						<font-awesome-icon
							class="btn-icon"
							:icon="['far', 'plus']"
							aria-hidden="true"
						/>
					</button>
				</div>
			</div>
			<channel-card
				v-for="channel in channels"
				:key="channel.cid"
				:channel="channel"
				:stream-profile="streamProfile"
			/>
			<loader
				v-if="hasNextPage"
				:enabled="autoload"
				:threshold="0.65"
				@intersect="fetchNextPage"
				@click.prevent="onLoadMoreClick"
			>
				{{ busy ? 'Please wait...' : 'Load more' }}
				<font-awesome-icon
					v-if="busy"
					class="ml-2"
					:icon="['far', 'circle-notch']"
					spin
				/>
				<font-awesome-icon
					v-else
					class="ml-2"
					:icon="['far', 'chevron-down']"
				/>
			</loader>
			<create-channel-panel
				:show="showCreateChannel"
				@close="showCreateChannel = false"
			/>
		</div>
		<router-view />
	</div>
</template>

<script>
import Loader from '@/components/Loader'
import { mapActions, mapGetters } from 'vuex'
import ChannelCard from '@/components/StreamChat/ChannelCard.vue'
import CreateChannelPanel from '@/components/Panels/CreateChannelPanel.vue'

const FIRST_PAGE = 0

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ChannelCard,
		CreateChannelPanel,
		Loader,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the another page can be loaded.
		 *
		 * @return {Boolean}
		 */
		hasNextPage() {
			return this.meta.offset !== this.meta.last_page
		},

		/**
		 * Determine if the current page is the chat show route.
		 *
		 * @return {Boolean}
		 */
		hasSubRoute() {
			return this.$route.name === 'app.chat.show'
		},

		/**
		 * Get the stream profile id.
		 *
		 * @return  {String}
		 */
		streamProfile() {
			return this.activeProfile?.stream_profile_id || ''
		},

		...mapGetters({
			activeProfile: 'messagingProfiles/activeProfile',
			channels: 'channels/channels',
			isActiveProfile: 'messagingProfiles/isActiveProfile',
			meta: 'channels/meta',
			partner: 'partner',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch more channels when it's needed.
		 *
		 * @return {void}
		 */
		async fetchNextPage() {
			if (!this.autoload) {
				return
			}

			this.busy = true

			const { scrollTop } = this.$refs.inbox

			try {
				await this.fetchChannels()
			} catch (e) {
				this.$alert.error('default.error')
			}

			this.busy = false

			await this.$nextTick()

			this.$refs.inbox.scrollTop = scrollTop + 24
		},

		/**
		 * Handle the create channel event click.
		 *
		 * @return {void}
		 */
		onCreateChannel() {
			this.showCreateChannel = true
		},

		/**
		 * Handle the the infinite scroll load-more button being clicked.
		 *
		 * @return {void}
		 */
		onLoadMoreClick() {
			if (!this.busy) {
				this.autoload = true

				this.fetchNextPage()
			}
		},

		...mapActions({
			fetchChannels: 'channels/get',
			reset: 'channels/reset',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Index',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (
				to.params.partner !== from.params.partner &&
				this.isActiveProfile
			) {
				this.reset()

				setTimeout(async () => {
					await this.fetchChannels(FIRST_PAGE)
				}, 1000)

				return
			}

			if (!this.isActiveProfile) {
				this.$router.push({ name: 'app.calls' })
			}
		},
	},

	/**
	 * The component's before created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		await this.fetchChannels(FIRST_PAGE)
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			autoload: true,
			busy: false,
			hasFetchedAllPages: false,
			showCreateChannel: false,
		}
	},
}
</script>
