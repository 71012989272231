<template>
	<div class="inbox-item" :class="hasUnreadMessages ? 'bg-blue-200' : ''">
		<div class="inbox-item-section pl-3">
			<router-link
				:to="{ name: 'app.chat.show', params: { id: channel.id } }"
				class="inbox-item-title"
			>
				<avatar :name="initials" :count="unreadCount" />
				<div>
					<div class="flex">
						<span>{{ displayName }}</span>
					</div>
					<h5 class="inbox-item-subtitle">{{ lastMessage }}</h5>
				</div>
			</router-link>
		</div>
		<div class="inbox-item-timestamp">
			{{ lastMessageAt }}
		</div>
	</div>
</template>

<script>
import filters from '@/config/filters'
import Avatar from '@/components/Avatar'
import { mapGetters } from 'vuex'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Avatar,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the display name.
		 *
		 * @return  {String}
		 */
		displayName() {
			return this.channelName(this.channel.id, this.streamProfile) || ''
		},

		initials() {
			if (this.displayName) {
				return this.displayName.charAt(0)
			}

			return ''
		},

		/**
		 * Determine if the given channel has unread messages
		 *
		 * @return  {[type]}  [return description]
		 */
		hasUnreadMessages() {
			return this.unreadCount > 0
		},

		/**
		 * Get the last message sent.
		 *
		 * @return {String}
		 */
		lastMessage() {
			let lastMessage = null
			const messageSets = this.channel.state.messageSets

			if (messageSets.length && messageSets[0]?.messages?.length) {
				lastMessage =
					messageSets[0].messages[messageSets[0].messages.length - 1]
			}

			if (lastMessage?.text) {
				return lastMessage?.text.length > 20
					? `${lastMessage?.text.slice(0, 20)}...`
					: lastMessage?.text
			}

			return 'Nothing yet...'
		},

		/**
		 * Get the last message time.
		 *
		 * @return  {String}
		 */
		lastMessageAt() {
			return this.channel.state.last_message_at
				? filters.toTimestamp(this.channel.state.last_message_at)
				: ''
		},

		...mapGetters({
			channelName: 'channels/channelName',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ChannelCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The channel to display.
		 *
		 * @type {Object}
		 */
		channel: {
			type: Object,
			required: true,
		},

		/**
		 * The stream profile id.
		 *
		 * @type {String}
		 */
		streamProfile: {
			type: String,
			required: true,
		},
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	created() {
		this.unreadCount = this.channel.countUnread()

		this.listener = this.channel.on(() => {
			this.unreadCount = this.channel.countUnread()
		})
	},

	/**
	 * The component's destroyed lifecycle hook.
	 *
	 * @return {void}
	 */
	destroyed() {
		this.listener.unsubscribe()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			unreadCount: 0,
		}
	},
}
</script>
